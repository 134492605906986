import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "name": "Charts and search",
  "route": "/charts-and-search"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Charts and search`}</h1>
    <p>{`Smartlike combines donation counters with crowdsource tagging to improve user experience and further increase creator visibility in a platform independent manner.`}</p>
    <p><strong parentName="p">{`Wisdom of the crowd:`}</strong>{` users who care about content visibility (creators and their fans) assign tags to content. Spam and abuse is mitigated by decentralized crowdsourced moderation.`}</p>
    <p><strong parentName="p">{`Why it matters:`}</strong>{` When we search for something to read or watch in a particular field of interest, it might get frustrating and time consuming. Although all major platforms have recommendation systems, most are not transparent and could be manipulative. Recommendations are not always reliable and are often fragmented. Smartlike delivers categorized ratings, based on popularity, proven and tagged by donators.`}</p>
    <p><strong parentName="p">{`Recommendations:`}</strong>{` Smartlike prepares a set of transparent and open sourced recommendation algorithms to choose from. They operate on public data and are open for audit to ensure there's no commercial or political manipulation.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      